<template>
  <div id="attestation">
    <div class="wei">
      <span
        style="
          width: 16px;
          height: 16px;
          background: #f32048;
          display: block;
          text-align: center;
          line-height: 16px;
          border-radius: 4px;
          margin-top: 5px;
          color: #fff;
        "
        >!</span
      >
      <span class="zi">未认证</span>
    </div>
    <div class="box">
      <Row :gutter="10" type="flex" justify="space-between">
        <Col class="boxItem" :span="7">
          <div class="title">学者认证</div>
          <div class="content">
            <span class="key">适用于：</span
            ><span class="value">某一行业研究领域学者</span>
          </div>
          <div class="content">
            <span class="key">需提供以下材料:</span
            ><span class="value">运营者身份证</span>
          </div>
          <div
            class="renzheng"
            @click="to"
            style="cursor: pointer"
            :class="isEdited ? 'good' : 'bad'"
          >
            {{ isEdited ? "立即认证" : "审核中" }}
          </div>
        </Col>
        <Col class="boxItem" :span="7">
          <div class="title">期刊认证</div>
          <div class="content">
            <span class="key">适用于：</span><span class="value">期刊</span>
          </div>
          <div class="content">
            <span class="key">需提供以下材料:</span
            ><span class="value">运营者身份证</span>
          </div>
          <div class="renzheng" style="color: #999999; border: 1px solid #999999">
            暂未开通
          </div>
        </Col>
        <Col class="boxItem" :span="7">
          <div class="title">机构认证</div>
          <div class="content">
            <span class="key">适用于：</span
            ><span class="value">各企业、公司、分支机构</span>
          </div>
          <div class="content">
            <span class="key">需提供以下材料:</span
            ><span class="value">运营者身份证、营业执照</span>
          </div>
          <div class="renzheng" style="color: #999999; border: 1px solid #999999">
            暂未开通
          </div>
        </Col>
      </Row>
    </div>
    <div class="limits">
      <div class="title">
        <span
          style="
            width: 16px;
            height: 16px;
            background: #f32048;
            display: block;
            text-align: center;
            line-height: 16px;
            border-radius: 4px;
            margin-top: 7px;
            color: #fff;
          "
          >!</span
        >
        <span class="zi">认证后您将获得以下权限</span>
      </div>
      <div class="limitItem">学者权限</div>
      <ul>
        <li>
          <div class="img"><img src="@/assets/svg/01.svg" alt="" /></div>
          <div class="name">开通百家号</div>
        </li>
        <li>
          <div class="img"><img src="@/assets/svg/02.svg" alt="" /></div>

          <div class="name">个人文献库</div>
        </li>
        <li>
          <div class="img"><img src="@/assets/svg/03.svg" alt="" /></div>

          <div class="name">资讯内容发布</div>
        </li>
        <li>
          <div class="img"><img src="@/assets/svg/04.svg" alt="" /></div>

          <div class="name">百家在线学者空间</div>
        </li>
      </ul>
      <div class="limitItem">期刊权限</div>
      <ul>
        <li>
          <div class="img"><img src="@/assets/svg/01.svg" alt="" /></div>

          <div class="name">开通百科号</div>
        </li>
        <li>
          <div class="img"><img src="@/assets/svg/05.svg" alt="" /></div>

          <div class="name">期刊文献库</div>
        </li>
        <li>
          <div class="img"><img src="@/assets/svg/03.svg" alt="" /></div>

          <div class="name">资讯内容发布</div>
        </li>
        <li>
          <div class="img"><img src="@/assets/svg/06.svg" alt="" /></div>

          <div class="name">征稿函发布</div>
        </li>
        <li>
          <div class="img"><img src="@/assets/svg/04.svg" alt="" /></div>

          <div class="name">百科在线期刊空间</div>
        </li>
        <li>
          <div class="img"><img src="@/assets/svg/07.svg" alt="" /></div>

          <div class="name">投审稿业务</div>
        </li>
        <!--        <li>[1]开通百科号</li>-->
        <!--        <li>[2]期刊文献库</li>-->
        <!--        <li>[3]资讯内容发布</li>-->
        <!--        <li>[4]征稿函发布</li>-->
        <!--        <li>[5]百科在线期刊空间</li>-->
        <!--        <li>[6]投审稿业务</li>-->
      </ul>
      <div class="limitItem">机构权限</div>
      <ul>
        <li>
          <div class="img"><img src="@/assets/svg/01.svg" alt="" /></div>

          <div class="name">开通机构号</div>
        </li>
        <li>
          <div class="img"><img src="@/assets/svg/05.svg" alt="" /></div>

          <div class="name">机构文库</div>
        </li>
        <li>
          <div class="img"><img src="@/assets/svg/03.svg" alt="" /></div>

          <div class="name">资讯内容发布</div>
        </li>
        <li>
          <div class="img"><img src="@/assets/svg/08.svg" alt="" /></div>

          <div class="name">文稿发布</div>
        </li>
        <li>
          <div class="img"><img src="@/assets/svg/09.svg" alt="" /></div>

          <div class="name">报告发布</div>
        </li>
        <!--        <li>[1]开通机构号</li>-->
        <!--        <li>[2]机构文库</li>-->
        <!--        <li>[3]资讯内容发布</li>-->
        <!--        <li>[4]文稿发布</li>-->
        <!--        <li>[5]报告发布</li>-->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "center",
  computed: {
    isEdited() {
      let bol1 = this.reviewStatus === -1 || this.reviewStatus === 3;
      let user = JSON.parse(sessionStorage.getItem("userInfo"));
      let bol2 =
        user.periodicalStatus === undefined
          ? true
          : user.periodicalStatus === 3
          ? true
          : false;
      console.log(bol1 && bol2);
      console.log(bol1, bol2);
      console.log(user.periodicalStatus === undefined);
      return bol1 && bol2;
    },
  },
  methods: {
    go() {
      if (!this.isEdited) return;
      this.$router.push("/user/periodicalRz");
    },
    to() {
      if (!this.isEdited) return;
      this.$router.push("/user/scholarCertification");
    },
    async type() {
      let res = await this.$apis.userServe.userEditTypes();
      console.log(res);
      this.reviewStatus = res.content.status;
    },
    async getUserInfo() {
      let data = await this.$apis.loginServe.getUserInfo();
      console.log(data, "data");
      let userObj = {
        token: sessionStorage.getItem("token"),
        hasLogin: true,
        userInfo: data.content,
      };
      // console.log(obj);
      this.$store.commit("loginInfo", userObj);
    },
  },
  data() {
    return {
      reviewStatus: 0,
    };
  },
  created() {
    this.type();
    // this.getUserInfo();
  },
  // beforeRouteEnter(to, from, next) {
  //   // next()
  //   let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
  //     ? JSON.parse(sessionStorage.getItem("userInfo"))
  //     : { user: { customerType: 1 } };
  //   if (to.name.indexOf("user") !== -1) {
  //     if (userInfo.user.customerType === 1) {
  //       next();
  //     } else {
  //       let topList = userInfo.permissionUrls["2"];
  //       next(topList[0].vuePath);
  //     }
  //   } else if (from.name.indexOf("Form") !== -1) {
  //     next("/user/contentMrg");
  //   } else {
  //     next();
  //   }
  // },
};
</script>

<style lang="scss" scoped>
#attestation {
  .wei {
    display: flex;
    justify-content: space-between;
    width: 100px;
    .zi {
      line-height: 21px;
      color: #f32048;
      height: 21px;
      font-size: 22px;
      font-family: Source Han Sans SC;
      font-weight: 400;
    }
  }
  .box {
    margin-top: 18px;
    padding-bottom: 20px;
    .boxItem {
      padding-top: 40px !important;
      padding-left: 41px !important;
      // width: 430px;
      height: 210px;
      background: #ffffff;
      border: 1px solid #999999;
      border-radius: 10px;
      .title {
        //width: 86px;
        height: 21px;
        font-size: 22px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #333333;
        margin-bottom: 30px;
      }
      .renzheng {
        width: 110px;
        height: 34px;
        border: 1px solid #00a4ff;
        border-radius: 12px;
        text-align: center;
        line-height: 34px;
        margin-top: 20px;
        color: #00a4ff;
        cursor: pointer;
        &.bad {
          color: rgb(153, 153, 153);
          border: 1px solid rgb(153, 153, 153);
        }
      }
      .content {
        //width: 196px;
        height: 14px;
        font-size: 14px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        //color: #999999;
        line-height: 14px;
        margin-top: 15px;
        .key {
          color: #999999;
        }
        .value {
          color: #333333;
        }
      }
    }
  }
  .limits {
    padding: 40px 0 40px 40px;
    background: #ffffff;
    border: 1px solid #999999;
    border-radius: 10px;
    .title {
      display: flex;
      margin-bottom: 30px;
      .zi {
        //width: 240px;
        margin-left: 10px;
        height: 21px;
        color: #333333;
        font-size: 22px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #333333;
      }
    }
    .limitItem {
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #999999;
    }
    ul {
      margin-bottom: 15px;
      margin-top: 15px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      li {
        margin-right: 40px;
        margin-bottom: 10px;
        width: 190px;
        height: 116px;
        background: #f6f8f9;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .name {
          width: 190px;
          text-align: center;
        }
        .img {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 190px;
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}
</style>
